@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i&subset=latin-ext);
html {
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  clear: both;
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button {
  cursor: pointer;
}

html input[type="button"] {
  cursor: pointer;
}

input[type="reset"] {
  cursor: pointer;
}

input[type="submit"] {
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  box-sizing: content-box;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

html {
  width: 100%;
  min-height: 100%;
}

body {
  color: #16212d;
  overflow-anchor: none;
  background: #fff;
  min-height: 100%;
  font-family: Open Sans, arial, sans-serif;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

a {
  color: #16212d;
  text-decoration: none;
}

a:hover, a:active {
  color: #ec86d0;
  text-decoration: underline;
}

img, input {
  max-width: 100%;
}

.smart-underline {
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 0 #fff, inset 0 -1px rgba(0, 0, 0, 0);
}

.smart-underline::selection {
  color: #fff;
  text-shadow: none;
  background: #8aa9b8;
}

.smart-underline:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 0 #fff, inset 0 -1px #6d94a6;
}

.smart-underline-header {
  text-shadow: 0 -2px #fff, 0 -1px #fff, 0 0 #fff, 2px -2px #fff, 2px -1px #fff, 2px 0 #fff, -2px -2px #fff, -2px -1px #fff, -2px 0 #fff, 1px -2px #fff, 1px -1px #fff, 1px 0 #fff, -1px -2px #fff, -1px -1px #fff, -1px 0 #fff, 0 -2px #fff, 0 -1px #fff, 0 0 #fff;
  text-decoration: none;
  transition: all .2s ease-in;
  box-shadow: inset 0 0 #fff, inset 0 -2px rgba(0, 0, 0, 0);
}

.smart-underline-header::selection {
  color: #fff;
  text-shadow: none;
  background: #8aa9b8;
}

.smart-underline-header:hover {
  text-decoration: none;
  transition: all .2s ease-out;
  box-shadow: inset 0 0 #fff, inset 0 -2px #6d94a6;
}

.small-top-padding {
  padding-top: 7px;
}

body {
  padding-top: 60px;
  font-family: Open Sans, arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 15px;
  font-family: Open Sans, arial, sans-serif;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, li a {
  transition: all .3s ease-in-out;
}

h1 {
  font-size: 25px;
}

h2 {
  font-size: 21px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 21px;
}

h6 {
  font-size: 18px;
}

p {
  margin: 0 0 15px;
  font-size: 16px;
  line-height: 1.4;
}

p:last-of-type {
  margin: 0;
}

.h1-size {
  font-size: 25px;
}

.h2-size {
  font-size: 21px;
}

.h3-size {
  font-size: 18px;
}

.h4-size {
  font-size: 24px;
}

.h5-size {
  font-size: 21px;
}

.h6-size {
  font-size: 18px;
}

.basic-headline {
  color: #6d94a6;
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.page-wrapper {
  text-align: left;
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
}

.page-wrapper:after, .page-wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.page-wrapper.with-bg {
  background-color: #fff;
}

.inner-wrapper {
  clear: both;
  width: 100%;
  position: relative;
}

.inner-wrapper:after, .inner-wrapper:before {
  content: "";
  clear: both;
  display: table;
}

.row {
  margin: 0;
}

.row:after, .row:before {
  content: "";
  clear: both;
  display: table;
}

.full-col {
  width: 96%;
}

.half-col {
  width: 46%;
}

.half-col, .full-col {
  clear: none;
  float: left;
  max-width: 100%;
  margin: 0 2%;
  padding: 0;
  display: inline;
  position: relative;
}

.half-col.no-margin, .full-col.no-margin, .m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.m-15 {
  margin: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.m-25 {
  margin: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.m-30 {
  margin: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.m-35 {
  margin: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.m-45 {
  margin: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.m-50 {
  margin: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.m-55 {
  margin: 55px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.m-60 {
  margin: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.m-65 {
  margin: 65px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.m-70 {
  margin: 70px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.m-75 {
  margin: 75px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.m-80 {
  margin: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.m-85 {
  margin: 85px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.m-90 {
  margin: 90px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.m-95 {
  margin: 95px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.m-100 {
  margin: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.table-default {
  width: 100%;
}

.table-default th, .table-default tr {
  text-align: left;
}

.table-default thead {
  border-bottom: 1px solid #b2d0dd;
}

.table-default thead tr {
  background-color: #fff;
}

.table-default tbody {
  border-bottom: 1px solid #b2d0dd;
}

.table-default tbody tr:nth-child(odd) {
  background-color: #fff;
}

.table-default tbody tr:nth-child(2n) {
  background-color: #b2d0dd;
}

.table-default td, .table-default th {
  padding: 10px;
}

.financial h1, .financial h2, .financial h3 {
  color: #6d94a6;
  font-size: 18px;
}

.financial h2 {
  padding: 15px 0 0;
}

.financial p {
  line-height: 1.7em;
}

.financial a {
  text-decoration: underline;
}

.financial a:hover {
  text-decoration: none;
}

.financial .issue-select {
  margin-bottom: 15px;
}

.financial .issue-select .issue-year {
  width: 150px;
  margin-right: 0;
}

.financial table {
  border-top: 5px solid #b2d0dd;
  width: 100%;
  margin-bottom: 15px;
}

.financial table a {
  color: #16212d;
  text-decoration: underline;
}

.financial table a:hover {
  color: #6d94a6;
  text-decoration: none;
}

.financial table thead {
  border-bottom: 1px solid #b2d0dd;
}

.financial table thead tr th {
  box-sizing: border-box;
  padding: 18px 10px 17px;
}

.financial table thead tr th.padding-bottom-0 {
  padding-bottom: 0;
}

.financial table tbody tr td {
  padding: 10px;
}

.financial table tbody tr:first-child td {
  padding-top: 20px;
}

.sticky-wrapper {
  min-height: 600px;
  margin-bottom: 20px;
  position: relative;
}

.sticky-wrapper .sticky-content {
  min-width: 322px;
}

.sticky-wrapper .fixed {
  position: fixed;
  top: 10px;
}

.ad-skyscrapper {
  padding: 0 15px;
}

.btn-row a.button-main, .btn-row .button-main, .btn-row .main-button, a.button-secondary, .button-secondary, a.button-main, .button-main, .main-button {
  text-align: center;
  border: 0;
  width: 200px;
  margin-top: 10px;
  padding: 20px 0;
  font-family: Open Sans Bold, arial, sans-serif;
  font-size: 14px;
  font-weight: bolder;
  line-height: 15px;
  text-decoration: none;
  transition: background-color .5s;
  display: block;
  position: relative;
}

.btn-row .button-main:disabled, .btn-row .main-button:disabled, .button-secondary:disabled, .button-main:disabled, .main-button:disabled {
  color: #707b87;
  background: #b2d0dd;
}

.btn-row .button-main:focus, .btn-row .main-button:focus, .button-secondary:focus, .button-main:focus, .main-button:focus {
  outline: 0;
}

.btn-row .button-main:active, .btn-row .main-button:active, .button-secondary:active, .button-main:active, .main-button:active {
  background: #02b3bb;
}

.btn-row .button-main > .icon, .btn-row .main-button > .icon, .button-secondary > .icon, .button-main > .icon, .main-button > .icon {
  background-position: center;
  width: 100%;
  height: 15px;
  display: inline-block;
}

.btn-row .icon.button-main, .btn-row .icon.main-button, .icon.button-secondary, .icon.button-main, .icon.main-button {
  width: 40px;
}

a.button-main, .button-main, .main-button {
  color: #fff;
  background: #2dccd3;
}

a.button-main:hover:enabled, .button-main:hover:enabled, .main-button:hover:enabled {
  color: #fff;
  background: #2dccd3;
  text-decoration: none;
}

a.button-main.clicked:hover:enabled, .button-main.clicked:hover:enabled, .main-button.clicked:hover:enabled {
  background: #02b3bb;
}

a.button-secondary, .button-secondary {
  color: #fff;
  background: #707b87;
}

a.button-secondary:hover:enabled, .button-secondary:hover:enabled {
  color: #fff;
  background: #1d223c;
  text-decoration: none;
}

.button-social {
  width: 78px;
  height: 26px;
  display: inline-block;
  position: relative;
}

.button-social:hover span {
  text-decoration: underline;
}

.button-social .button-social-icon {
  width: 20px;
  height: 15px;
  position: absolute;
  top: 0;
}

.button-social .button-social-icon div {
  height: 15px;
}

.button-social span {
  color: #fff;
  vertical-align: middle;
  padding-left: 33px;
  font-size: 11px;
  font-weight: bold;
  line-height: 26px;
  display: inline-block;
}

@media (max-width: 800px) {
  .button-social span {
    text-align: center;
    width: 100%;
    padding: 0 0 0 10px;
  }
}

.button-facebook {
  background-color: #4868ba;
}

.button-facebook .button-social-icon {
  top: 5px;
  left: 12px;
}

.button-facebook .button-social-icon svg {
  height: 15px;
}

.button-twitter {
  background-color: #5db3e6;
}

.button-twitter .button-social-icon {
  top: 5px;
  left: 7px;
}

.button-twitter .button-social-icon svg {
  height: 15px;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.block-center {
  margin-left: auto;
  margin-right: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.margin-top {
  margin-top: 15px;
}

.margin-bottom {
  margin-bottom: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.clearfix:before, .clearfix:after {
  contain: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.gam-wrapper {
  clear: both;
  display: block;
}

form.default-form:after, form.default-form:before {
  content: "";
  clear: both;
  display: table;
}

form.default-form .captcha:after {
  content: " ";
  clear: both;
  display: block;
}

form.default-form .captcha .captcha-img {
  width: 45%;
  height: 39px;
}

form.default-form .captcha .captcha-field {
  float: right;
  width: 45%;
  margin-top: 0;
}

form.default-form input {
  box-sizing: border-box;
  max-width: 100%;
}

form.default-form input.input-text {
  color: #16212d;
  border: 1px solid #b2d0dd;
  border-radius: 3px;
  outline: none;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  font-weight: 400;
  line-height: 18px;
  transition: all .3s ease-in-out;
  display: block;
}

form.default-form input[type="text"] {
  color: #16212d;
  border: 1px solid #b2d0dd;
  border-radius: 3px;
  outline: none;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  font-weight: 400;
  line-height: 18px;
  transition: all .3s ease-in-out;
  display: block;
}

form.default-form input.input-text:disabled {
  color: #b2d0dd;
  background-color: #fff;
  border-color: #b6d2df;
}

form.default-form input[type="text"]:disabled {
  color: #b2d0dd;
  background-color: #fff;
  border-color: #b6d2df;
}

form.default-form input.input-text:hover:disabled {
  cursor: not-allowed;
}

form.default-form input[type="text"]:hover:disabled {
  cursor: not-allowed;
}

form.default-form input.input-text:hover:enabled {
  border-color: #b2d0dd;
}

form.default-form input[type="text"]:hover:enabled {
  border-color: #b2d0dd;
}

form.default-form input.input-text:focus, form.default-form input.input-text:hover:focus {
  border: 1px solid #2dccd3;
}

form.default-form input[type="text"]:focus {
  border: 1px solid #2dccd3;
}

form.default-form input[type="text"]:hover:focus {
  border: 1px solid #2dccd3;
}

form.default-form input.submit-button {
  color: #fff;
  background-color: #6d94a6;
  border: 0;
  outline: none;
  width: 200px;
  margin-top: 20px;
  padding: 5px;
  font-weight: 700;
  display: block;
}

form.default-form textarea {
  box-sizing: border-box;
  resize: vertical;
  color: #16212d;
  border: 1px solid #b2d0dd;
  border-radius: 3px;
  outline: none;
  width: 100%;
  max-width: 100%;
  min-height: 80px;
  margin-top: 20px;
  padding: 5px;
  transition: all .3s ease-in-out;
  display: block;
}

form.default-form textarea:disabled {
  color: #b2d0dd;
  background-color: #fff;
  border-color: #b6d2df;
}

form.default-form textarea:hover:disabled {
  cursor: not-allowed;
}

form.default-form textarea:hover:enabled {
  border-color: #b2d0dd;
}

form.default-form textarea:focus, form.default-form textarea:hover:focus {
  border: 1px solid #707b87;
}

form.default-form .success-message {
  float: left;
  color: #6d94a6;
  margin-left: 10px;
  display: none;
}

form.default-form .form-field {
  clear: both;
  margin-bottom: 20px;
}

form.default-form .form-field:after, form.default-form .form-field:before {
  content: "";
  clear: both;
  display: table;
}

form.default-form .form-field label {
  float: left;
  color: #16212d;
  width: 100%;
  padding: 7px 7px 7px 0;
}

form.default-form .form-field input {
  float: left;
  margin-top: 0;
}

form.default-form .form-field input[type="checkbox"] {
  margin: 7px;
}

form.default-form .form-field .error-message {
  float: left;
  color: #6d94a6;
  margin-top: 5px;
  font-size: 18px;
  display: none;
}

form.default-form .form-field.with-error .error-message {
  display: block;
}

form.default-form .form-field.with-error input, form.default-form .form-field.with-error textarea, form.default-form .form-field.with-error input:hover, form.default-form .form-field.with-error textarea:hover {
  border: 1px solid #6d94a6;
}

form.default-form .form-field.with-error label {
  color: #6d94a6;
}

form.default-form .form-field .counter {
  color: #707b87;
  margin: 5px 0;
}

form.default-form .form-field-info {
  width: 100%;
}

form.default-form .form-field-info .dataimg-circle-info {
  float: right;
  width: 15px;
  height: 15px;
  position: relative;
}

form.default-form .form-field-info .dataimg-circle-info path {
  fill: #b2d0dd;
  transition: all .3s ease-in-out;
}

form.default-form .form-field-info .dataimg-circle-info:hover path {
  fill: #707b87;
}

form.default-form .form-field-info .dataimg-circle-info[data-tooltip]:hover:before {
  content: "";
  opacity: 1;
  border-top: 15px solid #fff;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  font-weight: 400;
  position: absolute;
  top: -15px;
  left: -5px;
}

form.default-form .form-field-info .dataimg-circle-info[data-tooltip]:hover:after {
  content: attr(data-tooltip);
  color: #16212d;
  z-index: 20;
  background: #fff;
  border-radius: 3px;
  width: 120px;
  padding: 10px;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  bottom: 30px;
  right: -20px;
}

form.default-form .form-group {
  width: auto;
  position: relative;
}

form.default-form .form-group input[type="text"] {
  padding-right: 60px;
}

form.default-form .form-group .currency-label {
  color: #16212d;
  border-left: 2px solid #b2d0dd;
  width: 55px;
  margin: 10px 0;
  padding-left: 10px;
  position: absolute;
  right: 0;
}

form.default-form .form-group .icon-label {
  width: 20px;
  height: 20px;
  margin: 10px;
  position: absolute;
  right: 0;
}

form.default-form .form-inline-button .main-button {
  float: right;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 120px;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 16px;
}

form.default-form .form-inline-button input[type="text"] {
  float: left;
  border-right: 0;
  border-radius: 3px 0 0 3px;
  width: calc(100% - 120px);
}

form.default-form .form-inline-button input[type="text"]:focus {
  border: 1px solid #707b87;
}

form.default-form .form-inline-button input[type="text"]:hover:focus {
  border: 1px solid #707b87;
}

form.default-form .form-inline-button.form-inline-button-short .main-button {
  width: 40px;
}

form.default-form .form-inline-button.form-inline-button-short .main-button .icon {
  width: 20px;
  height: 20px;
  display: inline-block;
}

form.default-form .form-inline-button.form-inline-button-short input[type="text"] {
  width: calc(100% - 40px);
}

form.default-form .form-short input[type="text"] {
  max-width: 90px;
}

form.default-form .form-code input[type="text"] {
  text-align: center;
  max-width: 30px;
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

form.default-form [type="checkbox"] {
  display: none;
}

form.default-form [type="checkbox"] + label {
  cursor: pointer;
  padding-left: 30px;
  font-weight: 700;
  position: relative;
}

form.default-form [type="checkbox"] + label:before {
  content: "";
  background: #fff;
  border: 1px solid #b2d0dd;
  border-radius: 3px;
  width: 18px;
  height: 18px;
  transition: all .3s ease-in-out;
  position: absolute;
  left: 0;
}

form.default-form [type="checkbox"] + label:after {
  content: "✓";
  color: #6d94a6;
  opacity: 0;
  font-size: 16px;
  line-height: .8;
  transition: all .3s;
  position: absolute;
  top: 10px;
  left: 5px;
  transform: scale(0);
}

form.default-form [type="checkbox"] + label:hover:before {
  border-color: #b2d0dd;
}

form.default-form [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

form.default-form [type="checkbox"]:disabled + label {
  color: #b2d0dd;
  cursor: not-allowed;
}

form.default-form [type="checkbox"]:disabled + label:before {
  color: #b2d0dd;
  cursor: not-allowed;
  border-color: #b6d2df;
}

form.default-form [type="checkbox"]:disabled + label:after {
  color: #8aa9b8;
  cursor: not-allowed;
}

form.default-form input[type="radio"] {
  display: none;
}

form.default-form input[type="radio"] + label {
  cursor: pointer;
  padding-left: 30px;
  font-weight: 700;
  position: relative;
}

form.default-form input[type="radio"] + label:before {
  content: "";
  background-color: #fff;
  border: 1px solid #b2d0dd;
  border-radius: 18px;
  width: 18px;
  height: 18px;
  transition: all .3s;
  position: absolute;
  top: 6px;
  left: 0;
}

form.default-form input[type="radio"] + label:after {
  content: "";
  color: #6d94a6;
  background: #6d94a6;
  border-radius: 12px;
  width: 0;
  height: 0;
  font-size: 0;
  transition: all .3s;
  position: absolute;
  top: 16px;
  left: 10px;
}

form.default-form input[type="radio"] + label:hover:before {
  border-color: #b2d0dd;
}

form.default-form input[type="radio"]:checked + label:after {
  width: 11.5px;
  height: 11.5px;
  top: 10px;
  left: 4px;
}

form.default-form input[type="radio"]:disabled + label {
  color: #b2d0dd;
  cursor: not-allowed;
}

form.default-form input[type="radio"]:disabled + label:before {
  border-color: #b6d2df;
}

form.default-form input[type="radio"]:disabled + label:after {
  background-color: #8aa9b8;
}

@media (max-width: 800px) {
  form.default-form input.input-text {
    border-radius: 0;
  }

  form.default-form input[type="text"] {
    border-radius: 0;
  }

  form.default-form textarea, form.default-form .form-inline-button .main-button {
    border-radius: 0;
  }

  form.default-form .form-inline-button input[type="text"] {
    border-radius: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel {
  tap-highlight-color: transparent;
  z-index: 1;
  width: 100%;
  display: none;
  position: relative;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-controls .owl-nav .owl-prev, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-dot {
  cursor: hand;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  min-height: 1px;
  position: relative;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  width: 100%;
  display: block;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .owl-video-wrapper {
  background: #000;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: url("owl.video.play.png") no-repeat;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  transition: scale .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  position: relative;
}

.no-js .owl-carousel {
  display: block;
}

.gallery-main-container, .gallery-vertical-container {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-main-container:after, .gallery-main-container:before, .gallery-vertical-container:after, .gallery-vertical-container:before {
  content: "";
  clear: both;
  display: table;
}

.gallery-main-container .gallery, .gallery-vertical-container .gallery {
  width: 100%;
  height: 91%;
}

.gallery-main-container .gallery .icon-loading, .gallery-vertical-container .gallery .icon-loading {
  width: 35px;
  height: 35px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container, .gallery-vertical-container .gallery .images-container {
  width: 100%;
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item, .gallery-vertical-container .gallery .images-container .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item img, .gallery-vertical-container .gallery .images-container .owl-item img {
  width: auto;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container .owl-item.advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.advertisement:after {
  content: "REKLAMA";
  color: #000;
  text-align: center;
  opacity: 0;
  z-index: -1;
  background: rgba(255, 255, 255, .3);
  width: 130px;
  height: 37px;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  transition: opacity .2s;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 65px);
}

.gallery-main-container .gallery .images-container .owl-item.active > .advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.active > .advertisement:after {
  opacity: 1;
}

.gallery-main-container .gallery .images-container .owl-stage-outer, .gallery-vertical-container .gallery .images-container .owl-stage-outer, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .btn, .gallery-vertical-container .gallery .btn {
  z-index: 10;
  cursor: pointer;
  background: rgba(0, 0, 0, .6);
  width: 9%;
  height: 91%;
  display: block;
  position: absolute;
}

.gallery-main-container .gallery .btn .icon-arrow, .gallery-vertical-container .gallery .btn .icon-arrow {
  width: 13px;
  height: 26px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .next, .gallery-vertical-container .gallery .next {
  right: 0;
}

.gallery-main-container .gallery .next .image-count, .gallery-vertical-container .gallery .next .image-count {
  text-align: center;
  color: #fff;
  margin: auto;
  font-size: 26px;
  font-weight: 300;
  position: absolute;
  bottom: 5px;
  left: 4%;
  right: 4%;
}

.gallery-main-container .image-description, .gallery-vertical-container .image-description {
  color: #aaa;
  padding: 20px 0;
}

.gallery-main-container .image-description div, .gallery-vertical-container .image-description div {
  display: inline-block;
}

.gallery-main-container .image-description .description, .gallery-vertical-container .image-description .description {
  padding: 0 5px 0 0;
}

.gallery-main-container .image-description .author, .gallery-vertical-container .image-description .author {
  font-style: italic;
}

.gallery-container.vertical .gallery-vertical-container {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container img[data-src^="http"], .gallery-container.vertical .gallery-vertical-container img[data-src^="http"] + .image-description {
  display: none;
}

.gallery-container.vertical .gallery-vertical-container img {
  margin: 10px 0 0;
}

.gallery-container.vertical .gallery-vertical-container .load-button {
  letter-spacing: 1px;
  margin: 20px auto;
  padding: 10px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description {
  padding: 20px 10px;
}

.gallery-container.vertical .gallery-vertical-container .image-description .image-description {
  clear: both;
  width: 100%;
  padding: 15px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description .author {
  float: left;
  display: block;
}

.gallery-container.vertical .gallery-vertical-container .image-description .photo-count {
  float: right;
  color: #fff;
  font-weight: 700;
}

.gallery-container.vertical .gallery-vertical-container .gallery {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container .images-container {
  margin-top: 35px;
}

.owl-nav.disabled {
  display: none;
}

.gallery-with-video .gallery-item .video-item {
  width: 81%;
  margin: auto;
}

@media (max-width: 800px) {
  .gallery-container {
    height: 350px;
    margin-top: 10px;
  }

  .gallery-container .gallery-container {
    width: 100%;
    height: 100%;
    margin: auto auto 60px;
  }

  .gallery-container .gallery-container .gallery {
    height: 100%;
    margin-top: 30px;
  }

  .gallery-container .gallery-container .gallery .images-container {
    height: 100%;
  }

  .gallery-container .gallery-container .gallery .btn {
    width: 16%;
    bottom: 0;
  }

  .gallery-container .gallery-container .gallery .btn .icon-arrow {
    top: 10%;
  }

  .gallery-container .gallery-container .image-description {
    padding: 10px;
  }

  .gallery-main-container .gallery .btn {
    width: 14%;
  }

  .gallery-main-container .gallery .btn .icon-arrow {
    width: 44px;
    height: 44px;
  }

  .gallery-main-container .gallery .next .image-count {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .gallery-container {
    height: 300px;
  }

  .gallery-container .gallery-container {
    height: 100%;
  }
}

@media (max-width: 330px) {
  .gallery-container {
    height: 250px;
  }

  .gallery-container .gallery-container {
    height: 100%;
  }
}

@media (max-width: 800px) {
  .gallery-container {
    margin-top: 45px;
  }

  .gallery-container .gallery-container .gallery {
    margin-top: 0;
  }

  .gallery-container .gallery-container .gallery .btn {
    height: 100%;
  }

  .gallery-container .gallery-container .gallery .btn .icon-arrow {
    top: 0;
  }

  .bg-color-dark .header-dark.super-mamy .header-container .titles-container {
    width: 90%;
    height: 6vw;
    top: 34px;
    left: 0;
  }

  .bg-color-dark .header-dark.super-mamy .header-container .titles-container a.title {
    letter-spacing: .19vw;
    font-size: 2.1875vw;
    line-height: 1.3em;
  }

  .bg-color-dark .header-dark.super-mamy .header-container .titles-container h1.article-title {
    overflow: initial;
    right: 0;
  }

  .bg-color-dark .header-dark.super-mamy .header-container .titles-container a.article-title {
    right: initial;
    color: #fff;
    float: left;
    clear: both;
    font-family: opensans-regular, Arial, sans-serif;
    font-size: 2.8125vw;
    line-height: 1.4;
    text-decoration: none;
    left: 20px;
  }
}

.header-dark {
  z-index: 100;
  background-color: #1e1e1e;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
}

.header-dark:after, .header-dark:before {
  content: "";
  clear: both;
  display: table;
}

.header-dark .header-container {
  width: 1080px;
  margin: auto;
}

.header-dark .header-container a {
  text-decoration: none;
}

.header-dark .header-container a:hover {
  text-decoration: underline;
}

.header-dark .header-container .logo {
  float: left;
  background-position: center;
  width: 190px;
  height: 50px;
  margin: 5px 15px 0 0;
}

.header-dark .header-container .gallery-close {
  color: #fff;
  float: right;
  margin: 20px 0;
  font-size: 14px;
  font-weight: bold;
  display: block;
  position: relative;
}

.header-dark .header-container .gallery-close span {
  text-transform: uppercase;
  position: relative;
  top: 5px;
}

.header-dark .header-container .gallery-close .icon {
  float: right;
  width: 25px;
  height: 25px;
  margin: 0 0 0 10px;
}

.header-dark .header-container .gallery-close:hover {
  text-decoration: underline;
}

.header-dark .header-container .titles-container {
  float: left;
  padding: 8px 20px 0;
  font-weight: bold;
}

.header-dark .header-container .titles-container a {
  text-decoration: none;
}

.header-dark .header-container .titles-container a:hover {
  text-decoration: underline;
}

.header-dark .header-container .titles-container .title {
  color: #b2d0dd;
  float: left;
  text-transform: uppercase;
  margin: 3px 0;
  font-size: 13px;
}

.header-dark .header-container .titles-container .article-title {
  color: #fff;
  float: left;
  clear: both;
  margin-top: 2px;
  font-size: 15px;
}

.bg-color-dark {
  padding-top: 0;
}

.bg-color-dark .header-dark {
  z-index: 100;
  background-color: #1e1e1e;
  width: 100%;
  height: 45px;
  padding: 0 10px;
  position: fixed;
  top: 0;
}

.bg-color-dark .header-dark:after, .bg-color-dark .header-dark:before {
  content: "";
  clear: both;
  display: table;
}

.bg-color-dark .header-dark .header-container {
  width: 100%;
}

.bg-color-dark .header-dark .header-container .logo {
  float: left;
  background-position: center;
  width: 156px;
  height: 35px;
  margin: 10px 0 0;
}

.bg-color-dark .header-dark .header-container .titles-container {
  height: 30px;
  position: absolute;
  top: 0;
  left: 175px;
  right: 0;
  overflow: hidden;
}

.bg-color-dark .header-dark .header-container .titles-container .title {
  display: none;
}

.bg-color-dark .header-dark .header-container .titles-container .article-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  line-height: 38px;
  position: absolute;
  top: 0;
  left: 0;
  right: 145px;
  overflow: hidden;
}

.bg-color-dark .header-dark .header-container .gallery-close {
  z-index: 101;
  background-color: #1e1e1e;
  margin: 10px 0;
  padding-left: 10px;
  font-size: 11px;
}

header .main-header {
  height: 45px;
  margin-bottom: 5px;
  position: relative;
}

header .main-header .header-menu {
  border-bottom: 1px solid #d3e6ef;
  height: 45px;
  padding: 0;
}

header .main-header .header-menu .dataimg-logo-horizontal {
  width: 156px;
  height: 35px;
  margin: 10px 0 0;
  display: inline-block;
}

header .main-header .header-menu .search .dataimg-search {
  width: 20px;
  height: 20px;
}

header .main-header .lines {
  background: #707b87;
  border-radius: .0928571rem;
  width: 1.3rem;
  height: .26rem;
  transition: all .2s;
  display: block;
  position: relative;
}

header .main-header .lines:before, header .main-header .lines:after {
  content: "";
  transform-origin: .0928571rem;
  background: #707b87;
  border-radius: .0928571rem;
  width: 1.3rem;
  height: .26rem;
  transition: all .2s;
  display: block;
  position: absolute;
  left: 0;
}

header .main-header .lines:before {
  top: .433333rem;
}

header .main-header .lines:after {
  top: -.433333rem;
}

header .main-header .lines-button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  border-radius: .185714rem;
  outline: 0;
  margin: 18px 0 0 5px;
  padding-right: 0;
  transition: all .2s;
}

header .main-header .lines-button span {
  outline: 0;
}

header .main-header .lines-button:hover {
  opacity: 1;
}

header .main-header .lines-button .lines {
  transition: background .2s .6s;
}

header .main-header .lines-button .lines:before, header .main-header .lines-button .lines:after {
  transform-origin: 50%;
  transition: top .2s .3s, transform .2s;
}

header .main-header .lines-button.close .lines {
  background: none;
  transition: background .2s;
}

header .main-header .lines-button.close .lines:before, header .main-header .lines-button.close .lines:after {
  width: 1.3rem;
  transition: top .3s, transform .2s .3s;
  top: 0;
}

header .main-header .lines-button.close .lines:before {
  transform: rotate3d(0, 0, 1, 45deg);
}

header .main-header .lines-button.close .lines:after {
  transform: rotate3d(0, 0, 1, -45deg);
}

header .menu {
  z-index: 51;
  background: #fff;
  width: 100%;
  margin-top: -15px;
  margin-bottom: 15px;
  transition: all .5s;
  position: relative;
  top: 0;
  left: 0;
}

header .menu.hide {
  width: 0;
  height: 0;
  display: none;
  left: 100%;
}

header nav .dropdown-menu {
  background: #2dccd3;
  width: 100%;
  font-weight: 700;
}

header nav .dropdown-menu:after, header nav .dropdown-menu:before {
  content: "";
  clear: both;
  display: table;
}

header nav .dropdown-menu a {
  height: auto;
}

header nav .dropdown-menu ul {
  z-index: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

header nav .dropdown-menu ul li, header nav .dropdown-menu li {
  display: block;
}

header nav .dropdown-menu li a {
  color: #fff;
  cursor: pointer;
  font-size: 21px;
  text-decoration: none;
}

header nav .dropdown-menu li a:hover, header nav .dropdown-menu li a:active {
  color: #16212d;
}

header nav .dropdown-menu li .dataimg-arrow-down-mobile {
  float: right;
  color: #b2d0dd;
  width: 20px;
  height: auto;
  transition: all .6s;
  position: relative;
  top: 25px;
  right: 12px;
}

header nav .dropdown-menu li .dataimg-arrow-down-mobile.opened {
  transform: rotate(180deg);
}

header nav .dropdown-menu li.dropdown-title {
  border-bottom: 1px solid #d3e6ef;
  width: 94%;
  height: auto;
  margin: 0 3%;
  padding: 5px 0;
  position: relative;
}

header nav .dropdown-menu li.dropdown-title:hover > a, header nav .dropdown-menu li.dropdown-title:active > a {
  color: #48535f;
}

header nav .dropdown-menu li.dropdown-title:hover > a path, header nav .dropdown-menu li.dropdown-title:active > a path {
  fill: #16212d;
}

header nav .dropdown-menu li.dropdown-title > a:hover:after, header nav .dropdown-menu li.dropdown-title a:active:after {
  display: none;
}

header nav .dropdown-menu li.dropdown-title > a {
  box-sizing: border-box;
  text-transform: uppercase;
  color: #fff;
  z-index: 1000;
  width: 80%;
  height: auto;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 600;
  display: block;
  position: relative;
}

header nav .dropdown-menu li.dropdown-title > a:hover, header nav .dropdown-menu li.dropdown-title > a:active {
  color: #48535f;
}

header nav .dropdown-menu li.dropdown-title > span {
  float: right;
  width: 25px;
  height: 25px;
  display: block;
}

header nav .dropdown-menu li ul {
  z-index: 2222;
  background: #b2d0dd;
  width: 100%;
  display: none;
  position: relative;
}

header nav .dropdown-menu li ul.second-level {
  transition: all .6s ease-in-out;
}

header nav .dropdown-menu li ul li a {
  padding: 24px 30px 12px 10px;
  display: block;
}

header nav .dropdown-menu li ul li ul {
  background: #707b87;
  margin-top: 30px;
  display: none;
}

header nav .dropdown-menu li ul li ul li {
  height: 65px;
}

header .main-header .header-menu .search {
  margin-top: 10px;
  padding: 2px 0 0;
}

header .main-header .header-menu .search .dataimg-search path {
  fill: #707b87;
}

body .article-big-vertical {
  border-bottom: 1px solid #d3e6ef;
  margin: 0 0 1em;
}

body .article-big-vertical .meta {
  display: none;
}

body .article-big-vertical .meta a {
  font-size: 16px;
  font-weight: 700;
}

body .article-big-vertical .meta.with-cat {
  border-top: 3px solid #ec86d0;
  padding: 10px 0;
  display: block;
}

body .article-big-vertical .meta.with-cat .category-name {
  margin-bottom: 0;
}

body .article-big-vertical .meta.with-cat .category-name a {
  color: #ec86d0;
  font-family: Open Sans Semibold, arial, sans-serif;
  font-size: 16px;
}

body .article-big-vertical .image-container {
  width: 100%;
}

body .article-big-vertical .image-container img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

body .article-big-vertical .image-container .circle-play {
  border-width: .7vw;
  width: 17vw;
  height: 17vw;
  top: 25%;
  left: 38%;
}

body .article-big-vertical .image-container .circle-play .arrow-play {
  border-top-width: 4vw;
  border-bottom-width: 4vw;
  border-left-width: 5vw;
  top: 29%;
  left: 44%;
}

body .article-big-vertical h2 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
}

body .article-big-vertical h2 a {
  color: #16212d;
  font-size: 18px;
}

body .article-big-vertical h2 a:hover, body .article-big-vertical h2 a:focus, body .article-big-vertical h2 a:active {
  color: #16212d;
  text-decoration: underline;
}

body .article-big-vertical + .first.top-line .article-link {
  border-top: 0;
  padding-top: 10px;
}

body .article-big-vertical.border-bottom-0 {
  border-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  body .article-big-vertical h2 {
    line-height: 3.5vw;
  }

  body .article-big-vertical h2 a {
    font-size: 3vw;
  }
}

.mobile article.article-big-vertical .image-container {
  display: block;
  position: relative;
}

.mobile article.article-big-vertical .image-container .circle-play {
  top: initial;
  bottom: 20px;
  right: 20px;
  left: initial;
  background: #2dccd3;
}

.mobile article.article-big-vertical .image-container .circle-play .arrow-play {
  border-left-color: #fff;
}

.mobile article .image-container .silhouette-image div {
  background-color: #6cdbe0;
  padding: 15%;
}

.article-link {
  border-bottom: 1px solid #d3e6ef;
  padding: 20px 0;
  overflow: auto;
}

.article-link .image-container {
  float: left;
  width: 322px;
  margin-right: 20px;
  padding-bottom: 0;
  display: block;
  position: relative;
}

.article-link .image-container .silhouette-image div {
  background-color: #d3e6ef;
  background-position: center;
  background-size: 80%;
  width: 322px;
  height: 215px;
}

.article-link .title-content h2 {
  float: left;
  max-width: 320px;
  margin: 10px 0 0;
  padding-top: 0;
  padding-left: 0;
  font-size: 21px;
  line-height: 36px;
}

.article-link .title-content h2 a {
  color: #16212d;
  text-decoration: none;
}

.article-link .title-content h2 a:hover {
  text-decoration: underline;
}

.top-line + .top-line .article-link {
  border-top: 0;
}

.meta .category-name {
  vertical-align: middle;
  padding-top: 5px;
  display: inline-block;
}

.meta .category-name a {
  color: #ec86d0;
  text-transform: uppercase;
  font-size: 13px;
  text-decoration: none;
}

.meta .category-name a:hover {
  color: #16212d;
  text-decoration: underline;
}

.article-link-simple h2 {
  margin-top: 0;
}

.article-link-simple h2 a {
  color: #2dccd3;
  font-size: 18px;
  text-decoration: underline;
}

.article-link-simple h2 a:hover {
  text-decoration: none;
}

.mobile article {
  position: relative;
}

.mobile article .article-link {
  padding: 20px 0;
}

.mobile article .article-link .image-container {
  float: left;
  width: 40%;
}

.mobile article .article-link .image-container .silhouette-image div {
  width: 100%;
  height: auto;
  padding-top: 66%;
}

.mobile article .article-link .image-container img {
  width: 100%;
  height: auto;
}

.mobile article .article-link .title-content {
  float: right;
  width: calc(60% - 20px);
}

.mobile article .article-link .title-content h2 {
  margin-top: 0;
  font-size: 16px;
  line-height: 1.2em;
}

.mobile article .meta .category-name {
  margin-top: -3px;
  margin-bottom: 10px;
  padding-top: 0;
}

.mobile article .meta .category-name a {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
}

.mobile article .circle-play {
  cursor: pointer;
  background: #fff;
  border-radius: 25vw;
  width: 7vw;
  height: 7vw;
  transition: all .3s;
  position: absolute;
  top: 32%;
  left: 41%;
}

.mobile article .circle-play .arrow-play {
  border-top: 1.5vw solid rgba(0, 0, 0, 0);
  border-bottom: 1.5vw solid rgba(0, 0, 0, 0);
  border-left: 2vw solid #16212d;
  font-size: 0;
  position: absolute;
  top: 31%;
  left: 42%;
}

.mobile article.first .article-link.category {
  border-top: 0;
  padding-top: 0;
}

.mobile article.last .article-link {
  border-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .mobile article .article-link .title-content h2 {
    font-size: 2.5vw;
  }
}

.mobile article .meta a {
  color: #ec86d0;
}

.mobile article .article-link .image-container .silhouette-image div {
  background-color: #6cdbe0;
  padding: 15%;
}

.mobile article .circle-play {
  top: 63%;
  right: 8%;
  left: initial;
  background: #2dccd3;
}

.mobile article .circle-play .arrow-play {
  border-left-color: #fff;
}

.title {
  text-transform: uppercase;
  margin: 10px 0;
  font-family: Open Sans Semibold, arial, sans-serif;
  font-size: 16px;
}

.title + .first .article-link {
  border-top: 0;
  padding-top: 5px;
}

.title.border-top {
  border-top: 3px solid #16212d;
  padding-top: 10px;
}

.title {
  color: #ec86d0;
}

.title.border-top {
  border-color: #ec86d0;
}

.title a {
  color: #ec86d0;
}

.tag-maintitle-simple-text {
  text-transform: lowercase;
  color: #16212d;
  font-size: 18px;
  font-weight: 400;
}

.pagination {
  width: 100%;
  margin: 30px 0;
  display: table;
}

.pagination .prev-page, .pagination .next-page {
  text-align: center;
  vertical-align: top;
  width: 40px;
  display: table-cell;
}

.pagination .prev-page a, .pagination .next-page a {
  background-color: #2dccd3;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  transition: background-color .2s;
  display: inline-block;
}

.pagination .prev-page a span, .pagination .next-page a span {
  background-position: center;
  background-size: 45%;
  width: 30px;
  height: 40px;
  display: inline-block;
}

.pagination .prev-page a:hover, .pagination .next-page a:hover {
  background-color: #2dccd3;
}

.pagination .page-nums {
  text-align: center;
  vertical-align: top;
  display: table-cell;
}

.pagination .page-nums a {
  color: #16212d;
  padding: 0 15px;
  font-size: 21px;
  line-height: 40px;
  display: inline-block;
}

.pagination .page-nums a.aktpage {
  color: #2dccd3;
}

.pagination .page-nums a:hover {
  text-decoration: underline;
}

.ajax-pagination .main-button {
  margin: 30px auto;
  font-size: 16px;
  text-decoration: none;
}

article + script + .first.top-line .article-link, article + script + script + .first.top-line .article-link {
  border-top: 0;
}

body .pagination .prev-page a, body .pagination .next-page a {
  border-radius: 0;
}

body .pagination .prev-page a span, body .pagination .next-page a span {
  background-size: 13px 13px;
}

body .ajax-pagination .main-button {
  text-transform: uppercase;
  width: 200px;
  margin: 30px auto;
  font-size: 14px;
}

body .ajax-pagination {
  font-size: 14px;
}

.list-article.article-nopic {
  border-bottom: 1px solid #d3e6ef;
  padding: 15px 0;
}

.list-article.article-nopic.last-article {
  border-bottom: 0;
}

.list-article.article-nopic h2 {
  margin-bottom: 0;
  line-height: 20px;
}

.list-article.article-nopic h2 a {
  font-size: 16px;
  display: block;
  position: relative;
}

.list-article.article-nopic h2 a:after {
  clear: both;
  content: "";
  display: block;
}

.list-article.article-nopic h2 a span {
  width: 90%;
  display: inline-block;
}

.list-article.article-nopic h2 a span:hover, .list-article.article-nopic h2 a span:active {
  text-decoration: underline;
}

.list-article.article-nopic h2 a .dataimg-arrow-light-right {
  width: 30px;
  position: absolute;
  top: 20%;
  right: -1%;
}

.list-article.article-nopic h2 a .dataimg-arrow-light-right path {
  fill: #2dccd3;
}

.list-article.article-nopic:last-of-type {
  border: 0;
}

.search-container {
  z-index: 1;
  display: inline-block;
  position: relative;
}

.search-container .dataimg-search {
  width: 20px;
  height: 20px;
  position: relative;
}

.search-container .link-with-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 0;
  position: relative;
  right: 0;
}

.search-container .link-with-icon .dataimg-search {
  position: relative;
  top: auto;
  left: auto;
}

.search-container form {
  width: 225px;
  padding: 0;
  display: none;
  position: absolute;
  top: -10px;
  right: 0;
}

.search-container form .replaced-submit {
  cursor: pointer;
  float: left;
  width: 100%;
  display: block;
  position: relative;
}

.search-container form .replaced-submit .form-field {
  margin: 0;
}

.search-container form .replaced-submit .form-inline-button .button-search {
  background-color: #6d94a6;
  width: 40px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.search-container form .replaced-submit .form-inline-button .button-search .dataimg-search {
  float: none;
  margin: auto;
}

.search-container form .replaced-submit .form-inline-button .button-search .dataimg-search path {
  fill: #fff;
}

.search-container form .replaced-submit input[type="text"] {
  width: calc(100% - 40px);
  font-size: 16px;
  line-height: 20px;
}

footer {
  width: 100%;
  max-width: 100%;
}

.footer-mobile {
  color: #707b87;
  text-align: center;
  padding: 1em 0;
  font-size: 14px;
  line-height: 1.3em;
}

.footer-mobile .version-switcher {
  width: 150px;
  margin: 1.5em auto;
}

.footer-mobile .version-switcher .mobile, .footer-mobile .version-switcher .desktop {
  float: right;
  margin: 0;
}

.footer-mobile .version-switcher .mobile a, .footer-mobile .version-switcher .desktop a {
  color: #707b87;
  font-size: 16px;
}

.footer-mobile .version-switcher .mobile a:hover, .footer-mobile .version-switcher .desktop a:hover {
  text-decoration: underline;
}

.footer-mobile .version-switcher .desktop {
  float: left;
}

.footer-mobile .version-switcher .desktop a .dataimg-desktop {
  position: relative;
  top: 2vw;
}

.main-article h1.title {
  color: #16212d;
  margin: 15px 0;
  line-height: 38px;
  display: block;
}

.main-article h1.title:after, .main-article h1.title:before {
  content: "";
  clear: both;
  display: table;
}

.main-article h2 {
  color: #16212d;
  margin-bottom: 0;
  padding-left: 60px;
  font-size: 21px;
  font-weight: bold;
}

.main-article h3 {
  color: #16212d;
  margin: 0;
  padding: 15px 0 0 60px;
  font-size: 18px;
  font-weight: bold;
}

.main-article .ads table {
  border-top: 0;
  margin-bottom: 0;
}

.main-article .image-container {
  padding-bottom: 15px;
}

.main-article .image-container .description {
  color: #6d94a6;
  text-align: left;
  width: 100%;
  padding-top: 15px;
  display: block;
}

.main-article ul {
  margin-top: 0;
  margin-left: 60px;
  padding: 0;
  list-style: none;
}

.main-article ul li {
  padding-left: 17px;
  font-size: 16px;
}

.main-article ul li:before {
  content: "•";
  color: #707b87;
  margin-left: -17px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  position: absolute;
}

.main-article ul.main {
  margin: 0;
}

.main-article ul.main > li {
  padding: 0;
}

.main-article ul.main > li:before {
  display: none;
}

.main-article .listing-controls {
  text-align: center;
}

.main-article .listing-controls button {
  display: none;
}

.main-article .listing-controls button.icon, .main-article .listing-controls button.start {
  float: right;
  margin-left: 5px;
}

.main-article .silhouette-image div {
  width: 690px;
  height: 408px;
}

.main-article .content p {
  padding-left: 60px;
  line-height: 22px;
}

.main-article .content p iframe {
  margin: 15px auto 15px 0;
}

.main-article .content p a {
  color: #2dccd3;
  text-decoration: underline;
}

.main-article .content p a:hover {
  text-decoration: none;
}

.main-article .content .twitter-tweet {
  margin-left: 60px;
}

.main-article .content table p {
  padding-left: 0;
}

.main-article .content .article-link {
  margin: 0;
}

.main-article .content .article-link .image-container {
  width: 322px;
}

.main-article .content .img-wrap {
  text-align: center;
}

.main-article .content .kw-in-art {
  background-color: #def9ff;
  margin-left: 60px;
  padding: 15px 10px;
  display: flex;
}

.main-article .content .kw-in-art .kw-logo {
  width: 50px;
}

.main-article .content .kw-in-art .kw-logo .icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  display: block;
}

.main-article .content .kw-in-art .kw-logo .icon svg path {
  fill: #1d223c;
}

.main-article .content .kw-in-art .kw-content {
  border-left: 1px solid #b2d0dd;
  padding-left: 20px;
}

.main-article .content .kw-in-art .kw-content strong {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  display: block;
}

.main-article .content .kw-in-art .kw-content a {
  letter-spacing: .5px;
  color: #fff;
  background-color: #2dccd3;
  border-radius: 9px;
  height: 18px;
  margin: 12px 10px 0 0;
  padding: 0 9px;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  display: inline-block;
}

.main-article .content .kw-in-art .kw-content a:first-letter {
  text-transform: uppercase;
}

.main-article .article-comments {
  margin: 0;
  padding: 15px 0;
}

.main-article .related-articles {
  margin: 15px 0;
}

.main-article .related-articles .title-container .title {
  color: #6d94a6;
  text-transform: uppercase;
  padding-left: 0;
  font-size: 16px;
  font-weight: 700;
}

.main-article .shares-and-date {
  clear: both;
  padding: 15px 0;
  overflow: auto;
}

.main-article .shares-and-date .share-buttons {
  float: left;
}

.main-article .shares-and-date .share-buttons .button-twitter {
  margin-left: 20px;
}

.main-article .shares-and-date .date {
  float: right;
  color: #6d94a6;
  font-size: 16px;
  line-height: 26px;
}

.main-article p {
  margin: 0;
  padding: 15px 0;
  font-size: 16px;
}

.main-article .meta-container {
  background: #fff;
  width: 100%;
  position: relative;
}

.main-article .meta-container:after, .main-article .meta-container:before {
  content: "";
  clear: both;
  display: table;
}

.main-article .meta-container .author-container {
  float: left;
  width: 100px;
}

.main-article .meta-container .author-container img.author-photo {
  border: 0;
  max-width: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.main-article .meta-container .meta {
  float: left;
  margin: 20px 0 15px 100px;
}

.main-article .meta-container .meta .category-name {
  float: left;
  text-transform: uppercase;
  margin-right: 30px;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}

.main-article .meta-container .meta .datetime {
  float: left;
  color: #b2d0dd;
  font-size: 18px;
  line-height: 20px;
}

.main-article .meta-container .meta .author-name-container {
  float: left;
  color: #b2d0dd;
  font-size: 18px;
  font-weight: 700;
}

.main-article .meta-container .meta .author-name-container a {
  color: #b2d0dd;
}

.main-article .meta-container .image-container:after, .main-article .meta-container .image-container:before {
  content: "";
  clear: both;
  display: table;
}

.main-article .article-gallery-container {
  background-color: #fff;
  padding: 20px;
}

.main-article .gallery h2 {
  color: #6d94a6;
  text-transform: uppercase;
  padding: 15px 0 5px;
  font-size: 16px;
  font-weight: normal;
}

.main-article .gallery .article-gallery-strip {
  margin-bottom: 20px;
}

.main-article .perex {
  margin: 0;
  padding: 0 0 0 60px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.main-article .perex p {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
}

.main-article figure {
  text-align: right;
  background-color: #fff;
  margin: 15px 0;
}

.main-article figure .image-in-article img {
  width: 100%;
  max-width: 614px;
  padding: 15px 0 0;
}

.main-article figure .image-in-article .description {
  color: #6d94a6;
  text-align: left;
  float: right;
  width: 100%;
  max-width: 614px;
  padding-top: 15px;
  display: block;
}

.main-article .related-articles-links {
  margin-top: 15px;
}

.main-article .authors-simple {
  padding: 15px 0;
}

.main-article.notlisted .bottom {
  display: none;
}

.main-article.listed ul.main > li {
  display: none;
  overflow: hidden;
}

.main-article.listed ul.main > li.active {
  display: block;
}

.main-article.listed .art-section-top {
  border-top: 1px solid #d3e6ef;
  padding-top: 20px;
}

.main-article.listed .art-section-top h2 {
  width: calc(100% - 100px);
}

.main-article.listed .sections-paging {
  position: relative;
}

.main-article.listed .sections-paging button {
  font-size: 16px;
  line-height: 15px;
}

.main-article.listed .sections-paging .start-listing {
  text-align: center;
  margin: 15px auto;
}

.main-article.listed .sections-paging .show-start {
  width: auto;
  padding: 13px 12px 12px;
  position: absolute;
  top: 1px;
  right: 0;
}

.main-article.listed .sections-paging .next, .main-article.listed .sections-paging .prev {
  width: 40px;
  height: 40px;
  padding: 0;
  position: absolute;
  top: 1px;
  right: 0;
}

.main-article.listed .sections-paging .next span, .main-article.listed .sections-paging .prev span {
  background-position: center;
  width: 15px;
  height: 25px;
  display: inline-block;
}

.main-article.listed .sections-paging .prev {
  right: 55px;
}

body .main-article h1 {
  margin-top: 15px;
  font-size: 21px;
  line-height: 25px;
}

body .main-article h1.title {
  text-transform: none;
  font-size: 21px;
  font-weight: 700;
  line-height: 25px;
}

body .main-article .perex {
  padding: 0;
  font-size: 16px;
  line-height: 24px;
}

body .main-article .perex p {
  font-size: 16px;
  line-height: 24px;
}

body .main-article ul {
  margin-left: 0;
}

body .main-article .content p {
  padding-left: 0;
  line-height: 24px;
}

body .main-article .content p:empty {
  display: none;
}

body .main-article .content h2 {
  padding-left: 0;
  font-size: 21px;
  line-height: 1.2em;
}

body .main-article .content h3 {
  padding-left: 0;
  font-size: 18px;
  line-height: 1.2em;
}

body .main-article .content .kw-in-art {
  margin-left: 0;
}

body .main-article .content .social-media__wrapper {
  margin-bottom: 20px;
}

body .main-article .content .social-media__wrapper .twitter-tweet {
  margin-left: 0;
}

body .main-article .shares-and-date .date {
  float: none;
  text-align: left;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1.3em;
}

body .main-article .shares-and-date .share-buttons {
  margin-top: 15px;
}

body .main-article .image-container {
  padding-bottom: 0;
}

body .main-article .image-container img {
  width: 100%;
  max-width: none;
}

body .main-article .article-link .image-container {
  float: left;
  max-width: 40%;
}

body .main-article .article-link .image-container .silhouette-image div {
  width: 100%;
  height: auto;
  padding-top: 66%;
}

body .main-article .article-link .image-container img {
  width: 100%;
  max-width: none;
  height: auto;
}

body .main-article .article-link .title-content {
  float: right;
  width: calc(60% - 20px);
}

body .main-article .article-link .title-content h2 {
  font-size: 16px;
  line-height: 1.2em;
}

body .main-article figure {
  color: #6d94a6;
  text-align: left;
}

body .main-article .image-in-article .description {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
}

body .main-article .imgInArt {
  max-width: 100%;
}

body .main-article .article-keywords {
  line-height: 30px;
}

body .main-article .article-keywords .title {
  margin-bottom: 0;
}

body .main-article.listed .art-section-top {
  min-height: 50px;
  padding-top: 10px;
}

body .main-article.listed .art-section-top h2 {
  width: calc(100% - 100px);
}

body .main-article.listed .bottom + .shares-and-date .share-buttons {
  margin-top: 0;
}

body .main-article.listed .bottom .section-paging {
  margin-bottom: 30px;
}

body .main-article.listed .bottom .section-paging .start-listing, body .main-article.listed .bottom .section-paging .show-start {
  text-align: center;
  margin: 15px auto;
}

body .main-article.listed .bottom .section-paging .show-start {
  margin-bottom: 30px;
}

body .main-article.listed .sections-paging {
  min-height: 0;
}

body .main-article.listed .sections-paging button {
  border-radius: 0;
  margin-top: 0;
  margin-bottom: 0;
}

body .main-article.listed .sections-paging .next, body .main-article.listed .sections-paging .prev {
  top: -5px;
}

body .main-article.listed .sections-paging .show-start {
  position: static;
  top: auto;
  left: auto;
}

body .article-editorial h1 {
  font-size: 18px;
}

body.mobile .main-article .article-link {
  padding-top: 20px;
}

.article-img .img-info {
  margin: 5px 0;
  font-size: 11px;
  font-weight: bold;
}

.article-img .img-info .circle-separator {
  vertical-align: middle;
  border: 3px solid #16212d;
  border-radius: 5px;
  margin: 0 10px;
  display: inline-block;
}

.art-40weeks {
  width: 100%;
}

.art-40weeks .arrow-wrapp {
  float: left;
  width: 11%;
  min-height: 1px;
}

.art-40weeks .arrow-wrapp .dataimg-arrow-light-left, .art-40weeks .arrow-wrapp .dataimg-arrow-light-right {
  width: 100%;
}

.art-40weeks .arrow-wrapp .dataimg-arrow-light-left path, .art-40weeks .arrow-wrapp .dataimg-arrow-light-right path {
  fill: #ec86d0;
}

.art-40weeks .arrow-wrapp .dataimg-arrow-light-right, .art-40weeks .arrow-wrapp.right {
  float: right;
}

.art-40weeks h1 {
  text-align: center;
  float: left;
  width: 46%;
  margin: 0;
  font-size: 3.5vw;
}

.art-40weeks .next-art, .art-40weeks .prev-art {
  float: left;
  text-align: right;
  color: #ec86d0;
  width: 19%;
  font-size: 3.5vw;
  font-weight: bold;
  display: inline-block;
  position: relative;
  left: -10px;
}

.art-40weeks .next-art {
  float: right;
  width: 13%;
  left: auto;
  right: -5px;
}

.art-40weeks .article-img {
  margin: 15px 0;
}

.art-40weeks .article-timeline {
  text-align: center;
  width: 95%;
  margin: 20px auto;
  display: none;
  position: relative;
}

.art-40weeks .article-timeline .owl-stage-outer {
  padding: 10px 0;
}

.art-40weeks .article-timeline .time-point {
  color: #2dccd3;
  border: 2px solid #ec86d0;
  border-radius: 6vw;
  width: 7vw;
  height: 7vw;
  padding-top: .5vw;
  font-size: 3.5vw;
  display: inline-block;
}

.art-40weeks .article-timeline .time-point.active {
  color: #fff;
  background: #2dccd3;
  border-color: #2dccd3;
  width: 9vw;
  height: 9vw;
  margin-top: -1vw;
  font-size: 5vw;
  font-weight: bold;
}

.art-40weeks .article-timeline .dataimg-arrow-light-left, .art-40weeks .article-timeline .dataimg-arrow-light-right {
  width: 8vw;
  padding: 10px 0;
  position: absolute;
  top: 0;
  left: -6vw;
}

.art-40weeks .article-timeline .dataimg-arrow-light-left path, .art-40weeks .article-timeline .dataimg-arrow-light-right path {
  fill: #ec86d0;
}

.art-40weeks .article-timeline .dataimg-arrow-light-right {
  left: auto;
  right: -6vw;
}

body .main-article .photo-desc {
  color: #6d94a6;
}

body .main-article .related-articles .title-container .title {
  color: #ec86d0;
}

.article-gallery-strip {
  margin: 10px 0 15px;
}

.article-gallery-strip > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.article-gallery-strip > ul > li {
  float: left;
  margin: 0 5px;
  padding: 0;
}

.article-gallery-strip > ul > li:before {
  display: none;
}

.article-gallery-strip > ul > li > a {
  display: block;
}

.article-gallery-strip > ul > li > a img {
  max-width: 110px;
  height: 64px;
}

.article-gallery-strip > ul > li:first-child {
  margin: 0 7px 0 0;
}

.article-gallery-strip > ul > li.photos-left {
  color: #2dccd3;
  margin: 0 0 0 5px;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  position: relative;
  top: 15px;
}

.article-gallery-strip > ul > li.photos-left .photos-count {
  text-align: center;
  margin-bottom: 5px;
  font-family: Open Sans Semibold, arial, sans-serif;
  font-size: 30px;
}

.article-gallery-strip > ul > li.photos-left .photos-count-desc {
  font-size: 14px;
  font-weight: 400;
}

.article-gallery-strip > ul > li.photos-left > a {
  color: #2dccd3;
  text-decoration: none;
  display: block;
}

.article-gallery-strip > ul > li.photos-left > a:hover {
  text-decoration: none;
}

.article-gallery-strip > ul > li.photos-left > a:hover .photos-count-desc {
  text-decoration: underline;
}

.login-box .login-modal-opener, .login-box .login-logout {
  color: #707b87;
  text-align: center;
  max-width: 100px;
  margin: 6px 5px 0;
  font-size: 9px;
  font-weight: bold;
  display: inline-block;
}

.login-box .login-modal-opener .dataimg-login, .login-box .login-logout .dataimg-login {
  width: 11px;
  margin: 3px auto 0;
}

.login-box .login-modal-opener .dataimg-login circle, .login-box .login-modal-opener .dataimg-login path, .login-box .login-logout .dataimg-login circle, .login-box .login-logout .dataimg-login path {
  fill: #707b87;
}

.onlinetalk {
  position: relative;
}

.question-answer {
  border-bottom: 1px solid #aaa;
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
  line-height: 1.7;
  transition: all .2s;
}

.question-answer .text-container {
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
  line-height: 1.7;
  transition: all .2s;
}

.question-answer .text-container .text-container {
  margin-bottom: 20px;
}

.question-answer .text-container .text-container:after, .question-answer .text-container .text-container:before {
  content: "";
  clear: both;
  display: table;
}

.question-answer .text-container .left {
  float: left;
  width: 15%;
}

.question-answer .text-container .left img {
  border: 1px solid #aaa;
  border-radius: 50%;
  max-width: 80%;
  height: auto;
  display: block;
  position: relative;
  top: 2px;
}

.question-answer .text-container .left .image-wrapper {
  border: 1px solid #aaa;
  border-radius: 50%;
  width: 40%;
  display: block;
}

.question-answer .text-container .right {
  float: right;
  width: 85%;
}

.question-answer .text-container .questioner {
  color: #000;
  margin: 0;
  font-size: 11px;
}

.question-answer .text-container.new {
  background: #aaa;
  border-radius: 5px;
}

.question-answer .chat-header {
  border-top: 3px solid #16212d;
  padding-top: 5px;
}

.question-answer .chat-header .onlinetalk-title {
  color: #16212d;
}

.question-answer .chat-header .onlinetalk-title.title-with-status {
  float: left;
  width: 85%;
}

.question-answer .chat-status {
  float: right;
  text-transform: uppercase;
  text-align: center;
  width: 15%;
  font-size: 18px;
  font-weight: bold;
}

.question-answer .chat-status .dataimg-online, .question-answer .chat-status .dataimg-online-live {
  width: 25px;
  margin: 0 auto;
}

.question-answer .chat-status .dataimg-online g, .question-answer .chat-status .dataimg-online-live g {
  fill: #16212d;
}

.chat-refresh {
  color: #2dccd3;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  display: block;
}

.chat-header .chat-status {
  float: right;
  text-transform: uppercase;
  text-align: center;
  width: 15%;
  font-size: 9px;
  font-weight: bold;
}

.chat-header .chat-status .dataimg-online, .chat-header .chat-status .dataimg-online-live {
  width: 25px;
  margin: 0 auto;
}

.chat-header .chat-status .dataimg-online g, .chat-header .chat-status .dataimg-online-live g {
  fill: #16212d;
}

.chat-header .chat-refresh {
  color: #2dccd3;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  display: block;
}

.chat-header {
  border-top: 3px solid #ec86d0;
  border-bottom: 1px solid #d3e6ef;
  padding-bottom: 20px;
}

.chat-header .onlinetalk-title {
  color: #ec86d0;
  font-size: 21px;
}

.chat-header a.button-main {
  margin-bottom: 20px;
  font-size: 16px;
}

.chat-header .chat-status {
  color: #ec86d0;
}

.chat-header .chat-status .dataimg-online g, .chat-header .chat-status .dataimg-online path, .chat-header .chat-status .dataimg-online-live g, .chat-header .chat-status .dataimg-online-live path {
  fill: #ec86d0;
}

.question-answer {
  border-color: #d3e6ef;
}

.question-answer .question .questioner {
  color: #ec86d0;
  font-size: 16px;
  font-weight: bold;
}

.question-answer .question .left {
  float: none;
  width: 0;
}

.question-answer .question .right {
  float: none;
  width: 100%;
}

.question-answer .question .text-container {
  margin-bottom: 5px;
}

.question-answer .answer .left img {
  border: 0;
  border-radius: 0;
  max-width: 80%;
}

form.chat-form .main-button {
  font-size: 16px;
}

form.chat-form .form-field .counter {
  color: #16212d;
  font-weight: bold;
}

form.chat-form .form-field label {
  font-weight: bold;
}

form.chat-form .form-field textarea {
  min-height: 175px;
}

.advisory h2 {
  margin-bottom: 10px;
}

.advisory .art-wrapp {
  border-bottom: 1px solid #d3e6ef;
  position: relative;
}

.advisory .art-wrapp .art-title-wrapp {
  width: 100%;
  height: 121px;
  max-height: 130px;
}

.advisory .art-wrapp .art-title-wrapp h4 {
  margin-bottom: 8px;
}

.advisory .art-wrapp .art-title-wrapp h4 a {
  padding-top: 10px;
  font-size: 18px;
  display: inline-block;
  overflow: hidden;
}

.advisory .art-wrapp .art-title-wrapp h4 a:hover {
  text-decoration: underline;
}

.advisory .art-wrapp .art-title-wrapp .name {
  width: 80%;
}

.advisory .art-wrapp .art-title-wrapp .name a {
  color: #ec86d0;
  font-weight: bold;
}

.advisory .art-wrapp .art-title-wrapp .job {
  position: absolute;
  bottom: 10px;
  left: 0;
}

.advisory .art-wrapp .art-title-wrapp .advisor-pic {
  position: absolute;
  top: 51px;
  right: 0;
}

.advisory .art-wrapp .art-title-wrapp .advisor-pic img {
  width: auto;
  height: 70px;
}

.advisory .art-wrapp.last {
  border-bottom: 0;
}

.advisor-article .img-info {
  font-size: 14px;
}

.advisor-article .question-circle .question-mark {
  color: #ec86d0;
  font-size: 48px;
  font-weight: bold;
}

.advisor-article .advice {
  margin-top: 30px;
  margin-bottom: 10px;
}

.advisor-article .advice .advisor-img {
  width: 25%;
  padding-right: 20px;
}

.advisor-article .advice .advisor-meta {
  width: 75%;
  margin-top: 10px;
}

.advisor-article .advice .advisor-meta a {
  color: #ec86d0;
  font-weight: bold;
}

.advisor-article .advice .advisor-meta .position {
  font-size: 16px;
}

.menu-bottom {
  background: #707b87;
  width: 100%;
  margin-top: 30px;
  padding: 7px 0;
  font-size: 11px;
}

.menu-bottom .link {
  text-align: center;
  border-right: 1px solid #d3e6ef;
  width: 32%;
  padding: 0 5px;
  display: inline-block;
}

.menu-bottom .link:last-of-type {
  border: 0;
}

.menu-bottom .link a {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
}

.author-profile {
  width: 100%;
  margin-bottom: 20px;
}

.author-profile .meta {
  margin: 20px 0 10px;
  padding-bottom: 10px;
}

.author-profile .meta .photo {
  width: 50%;
  max-width: 100px;
  margin: 0 auto;
}

.author-profile .meta .photo img {
  border: 1px solid #d3e6ef;
}

.author-profile .meta .name {
  color: #ec86d0;
  text-align: center;
  margin: 10px 0;
  font-size: 16px;
}

.author-profile .meta .position {
  color: #16212d;
  text-align: center;
  font-size: 14px;
}

.list-article-simple {
  border-top: 1px solid #d3e6ef;
}

.list-article-simple h2 {
  margin: 0;
  padding: 10px 0;
}

.list-article-simple h2 a {
  color: #16212d;
  font-size: 18px;
  text-decoration: none;
}

.list-article-simple h2 a:hover {
  text-decoration: underline;
}

.proverbs {
  font-size: 16px;
}

.proverbs p {
  margin-bottom: 5px;
}

.proverbs .proverbs-list {
  border-bottom: 1px solid #d3e6ef;
}

.proverbs .proverbs-list .saying {
  width: 80%;
  padding: 10px 0;
}

.proverbs .proverbs-list .saying h2 {
  margin-bottom: 0;
  font-size: 4vw;
  line-height: 7vw;
}

.proverbs .proverbs-list .saying .date {
  font-size: 3vw;
}

.proverbs .proverbs-list .saying .circle-separator {
  border: 3px solid #707b87;
  border-radius: 4px;
  margin: 0 10px;
  display: inline-block;
}

.proverbs .proverbs-list .eval {
  text-align: right;
  width: 20%;
}

.proverbs .proverbs-list .eval .num {
  color: #2dccd3;
  margin: 10px 0 0;
  font-family: Open Sans Semibold, arial, sans-serif;
  font-size: 7vw;
  line-height: 7vw;
}

.proverbs .proverbs-list .eval .num.user-num {
  margin-left: 5px;
  font-size: 11px;
}

.proverbs .proverbs-list .eval .rate-description {
  font-size: 3vw;
}

.proverbs .sort-choice {
  margin-bottom: 10px;
  font-size: 14px;
}

.proverbs .sort-choice span {
  margin-right: 40px;
}

.proverbs .line-choice {
  border-bottom: 1px solid #d3e6ef;
  position: relative;
}

.proverbs .line-choice .arrow-down {
  border-top: 10px solid #d3e6ef;
  border-left: 10px solid rgba(0, 0, 0, 0);
  border-right: 10px solid rgba(0, 0, 0, 0);
  display: inline-block;
  position: absolute;
  top: 0;
  left: 23px;
}

.proverbs .line-choice .arrow-down .inner {
  border-top: 9px solid #fff;
  border-left: 9px solid rgba(0, 0, 0, 0);
  border-right: 9px solid rgba(0, 0, 0, 0);
  display: inline-block;
  position: absolute;
  top: -10px;
  left: -9px;
}

.proverbs.proverbs-article a.catname {
  text-transform: uppercase;
  color: #ec86d0;
  font-family: Open Sans Semibold, arial, sans-serif;
}

.proverbs.proverbs-article h1 {
  width: 70%;
  padding-right: 20px;
  font-size: 5vw;
}

.proverbs.proverbs-article .proverb-img {
  width: 30%;
}

.proverbs.proverbs-article .proverbs-votes {
  border-bottom: 1px solid #d3e6ef;
  margin: 5px 0;
  padding: 10px 0;
}

.proverbs.proverbs-article .proverbs-votes a, .proverbs.proverbs-article .proverbs-votes span.star {
  cursor: pointer;
  background: url("../../../images/blesk-prozeny/prima-mamy/materstvi-sprite.png?v=4") -280px -134px no-repeat;
  width: 30px;
  height: 28px;
  margin: 0 10px 0 0;
  display: inline-block;
}

.proverbs.proverbs-article .proverbs-votes a.vote-stat, .proverbs.proverbs-article .proverbs-votes span.star.vote-stat {
  background-position: -280px -96px;
}

.proverbs.proverbs-article .proverbs-votes a.last, .proverbs.proverbs-article .proverbs-votes span.star.last {
  margin-right: 30px;
}

.proverbs.proverbs-article .proverbs-votes a.active, .proverbs.proverbs-article .proverbs-votes a:hover, .proverbs.proverbs-article .proverbs-votes a.active-fix {
  background-position: -280px -96px;
}

.proverbs.proverbs-article .thanks {
  color: #fff;
  background: #2dccd3;
  margin: 20px 0;
  padding: 5px 10px;
  display: none;
}

.proverbs .proverbs-form .subtitle {
  font-weight: 700;
}

.timeline {
  min-width: 307px;
  margin: 12vw auto 20px;
}

.timeline .hide {
  display: none;
}

.timeline .timeline-carousel {
  height: 1px;
  overflow: hidden;
}

.timeline .timeline-weeks {
  text-align: center;
  width: 95%;
  margin: 0 auto;
  font-size: 9px;
  position: relative;
}

.timeline .timeline-weeks .owl-stage-outer {
  padding: 10px 0;
}

.timeline .timeline-weeks .dataimg-arrow-light-left, .timeline .timeline-weeks .dataimg-arrow-light-right {
  width: 9vw;
  padding: 10px 0;
  position: absolute;
  top: -1vw;
  left: -6vw;
}

.timeline .timeline-weeks .dataimg-arrow-light-left path, .timeline .timeline-weeks .dataimg-arrow-light-right path {
  fill: #2dccd3;
}

.timeline .timeline-weeks .dataimg-arrow-light-right {
  left: auto;
  right: -6vw;
}

.timeline .timeline-main {
  color: #2dccd3;
  height: 30px;
  margin-top: 10px;
  font-family: Open Sans Semibold, arial, sans-serif;
}

.timeline .timeline-main .timeline-item {
  text-align: center;
  text-transform: uppercase;
  border-top: 1px solid #d3e6ef;
  width: 15vw;
  margin: 0 1.7vw;
  padding-top: 15px;
  font-size: 9px;
  display: inline-block;
  position: relative;
}

.timeline .timeline-main .timeline-item:last-of-type {
  margin-right: 0;
}

.timeline .timeline-main .timeline-item:first-of-type {
  margin-left: 0;
}

.timeline .timeline-main .timeline-item a {
  color: #2dccd3;
}

.timeline .timeline-main .timeline-item .circle-full {
  width: 100%;
  position: absolute;
  top: -8vw;
  left: 0;
}

.timeline .timeline-main .timeline-item .circle-full div {
  background: #ec86d0;
  border: 3.5vw solid #ec86d0;
  border-radius: 4vw;
  width: 1px;
  margin: 0 auto;
}

.timeline .timeline-main .timeline-item .circle-empty {
  width: 100%;
  position: absolute;
  top: -7vw;
  left: 0;
}

.timeline .timeline-main .timeline-item .circle-empty div {
  width: 12vw;
  margin: 0 auto;
}

.timeline .timeline-sex, .timeline .timeline-child {
  font-size: 9px;
  position: relative;
}

.timeline .timeline-sex .sex-point, .timeline .timeline-child .sex-point {
  float: left;
  text-align: center;
  width: 29%;
  margin-right: 6vw;
  position: relative;
}

.timeline .timeline-sex .sex-point.last, .timeline .timeline-child .sex-point.last {
  margin-bottom: 10px;
  margin-right: 0;
}

.timeline .timeline-sex .sex-point span, .timeline .timeline-child .sex-point span {
  color: #16212d;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  font-size: 3vw;
  position: absolute;
  top: -9vw;
  left: 0;
}

.timeline .timeline-child .sex-point {
  width: 20%;
}

.timeline .timeline-bottom {
  color: #2dccd3;
  text-transform: uppercase;
  text-align: center;
  border-top: 1px solid #d3e6ef;
  width: 95%;
  margin: 0 auto;
  padding-top: 12px;
  font-family: Open Sans Semibold, arial, sans-serif;
  position: relative;
}

.timeline .timeline-arrow-down {
  width: 100%;
  position: absolute;
  top: -1px;
  left: 0;
}

.timeline .timeline-arrow-down div {
  border-top: 5px solid #d3e6ef;
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  width: 1px;
  margin: 0 auto;
}

.timeline .time-point {
  color: #2dccd3;
  border: .5vw solid #ec86d0;
  border-radius: 6vw;
  width: 7vw;
  height: 7vw;
  padding-top: .4vw;
  font-size: 3.5vw;
  font-weight: bold;
  display: inline-block;
}

.timeline .timeline-button {
  margin: 10px auto;
}

@media (min-width: 780px) {
  .timeline .timeline-main .timeline-item {
    width: 14%;
  }

  .timeline .timeline-main .timeline-item .circle-empty {
    top: -5vw;
  }

  .timeline .timeline-main .timeline-item .circle-empty div {
    width: 9vw;
  }

  .timeline .timeline-main .timeline-item .circle-full {
    top: -6vw;
  }

  .timeline .timeline-main .timeline-item .circle-full div {
    border-width: 2.5vw;
  }

  .timeline .timeline-sex .sex-point, .timeline .timeline-child .sex-point {
    width: 26%;
  }

  .timeline .timeline-sex .sex-point span, .timeline .timeline-child .sex-point span {
    font-size: 21px;
    top: -6vw;
  }

  .timeline .timeline-child .sex-point {
    width: 20%;
  }
}

.breadCrumb {
  box-sizing: border-box;
  background-color: #fff;
  margin: 0 auto;
  padding: 7px 0;
  font-size: 12px;
  display: none;
}

.breadCrumb a {
  color: #aaa;
  display: inline;
}

.breadCrumb a:hover {
  color: #ec86d0;
}

.breadCrumb .arrow {
  border-top: 4px solid rgba(0, 0, 0, 0);
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  border-left: 6px solid #cad5df;
  margin: 0 5px;
  font-size: 0;
  display: inline-block;
}

.breadcrumbs-box .breadCrumb {
  display: block;
}

@font-face {
  font-family: opensans-regular;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: opensans-bold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.woff") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.cookie-bar {
  text-align: center;
  color: #fff;
  z-index: 1000000;
  background-color: rgba(50, 50, 50, .95);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0;
  font-family: opensans-regular, sans-serif;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.cookie-bar a, .cookie-bar a:hover {
  color: inherit;
}

.cookie-bar__title {
  font-family: opensans-bold, sans-serif;
  font-size: 22px;
  line-height: 26px;
}

.cookie-bar__title br {
  display: inline;
}

.cookie-bar__text {
  padding: 8px 0 13px;
  font-size: 12px;
  line-height: 16px;
}

.cookie-bar__text br {
  display: none;
}

.cookie-bar__enable-btn {
  text-transform: uppercase;
  cursor: pointer;
  background-color: red;
  width: 140px;
  height: 35px;
  margin: 0 10px 13px auto;
  font-size: 14px;
  line-height: 35px;
  text-decoration: none;
  display: inline-block;
}

.cookie-bar__more-info {
  margin-bottom: 5px;
  font-size: 11px;
  line-height: 15px;
  text-decoration: underline;
  display: inline-block;
}

.cookie-bar__more-info:hover {
  text-decoration: none;
}

.cookie-bar__close-btn {
  cursor: pointer;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  overflow: hidden;
}

.cookie-bar__close-btn:before, .cookie-bar__close-btn:after {
  content: "";
  background: #fff;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  left: 0;
}

.cookie-bar__close-btn:before {
  transform: rotate(45deg);
}

.cookie-bar__close-btn:after {
  transform: rotate(-45deg);
}

.cookie-bar__close-btn--thick:before, .cookie-bar__close-btn--thick:after {
  height: 3px;
  margin-top: -2px;
}

.box-admin article {
  float: left;
  width: 48%;
  min-height: 205px;
  padding: 4px 0;
}

.box-admin article:nth-child(odd) {
  float: right;
}

.box-admin article a {
  color: #16212d;
  text-transform: initial;
  font-weight: bold;
}

.main-article .article-gallery-container {
  float: left;
  width: 100%;
  margin-top: 10px;
  padding: 10px 0 15px;
  position: relative;
}

.main-article .article-gallery-container .gallery-strip {
  float: left;
  position: relative;
}

.main-article .article-gallery-container .gallery-strip ul {
  float: left;
  margin: 0;
  padding-left: 0;
  list-style: none;
  position: relative;
}

.main-article .article-gallery-container .gallery-strip ul li {
  float: left;
  width: 110px;
  margin: 0 6px 0 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}

.main-article .article-gallery-container .gallery-strip ul li .image {
  float: left;
  width: 110px;
  display: block;
  position: relative;
}

.main-article .article-gallery-container .gallery-strip ul li .image img {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}

.main-article .article-gallery-container .gallery-strip ul li:before {
  content: "";
  position: inherit;
  line-height: 0;
  display: none;
}

.main-article .article-gallery-container .gallery-strip ul li:last-of-type {
  width: 60px;
  margin: 0;
  font-size: 50px;
  font-weight: bold;
  line-height: 36px;
  text-decoration: none;
  top: 0;
}

.main-article .article-gallery-container .gallery-strip ul li:last-of-type a {
  text-align: center;
  display: block;
}

.main-article .article-gallery-container .gallery-strip ul li:last-of-type a .additional-value {
  color: #2dccd3;
  padding-top: 15px;
  font-size: 30px;
  line-height: 25px;
  text-decoration: none;
  display: block;
}

.main-article .article-gallery-container .gallery-strip ul li:last-of-type a .additional-value .gallery {
  text-transform: lowercase;
  font-size: 14px;
  font-weight: 400;
}

@font-face {
  font-family: sansregular;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?v=9");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.woff?v=9") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.ttf?v=9") format("truetype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-regular-webfont.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: open_sanslight;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.eot?v=9");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.woff?v=9") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.ttf?v=9") format("truetype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-light-webfont.svg#open_sanslight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: sanssemibold;
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.eot?v=9");
  src: url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.woff?v=9") format("woff"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.ttf?v=9") format("truetype"), url("//img2.cncenter.cz/fonts/open-sans/opensans-semibold-webfont.svg#open_sanssemibold") format("svg");
  font-weight: normal;
  font-style: normal;
}

.seo-box {
  text-align: center;
  border: 1px solid #cad5df;
  border-radius: 5px;
  margin: 0 0 20px;
  padding: 15px 15px 20px;
  overflow: hidden;
}

.seo-box a {
  color: #ec86d0;
}

.seo-box a:hover, .seo-box a:focus {
  color: #2dccd3;
}

.seo-box .seo-box__btn {
  text-align: center;
  color: #fff;
  background: #ec86d0;
  border: none;
  width: 200px;
  margin: 0 auto;
  padding: 10px;
  display: block;
}

.seo-box .seo-box__btn:hover, .seo-box .seo-box__btn:focus {
  color: #fff;
  text-decoration: none;
}

.seo-box .toggle-paragraphs {
  cursor: pointer;
  width: 40px;
  margin: 10px auto 0;
  display: block;
}

.seo-box .toggle-paragraphs svg {
  transform: rotate(270deg);
}

.seo-box .toggle-paragraphs path {
  fill: #ec86d0;
}

.seo-box.partialHidedP {
  position: relative;
}

.seo-box.partialHidedP p:not(:first-child) {
  display: none;
}

.seo-box.partialHidedP .seo-box__content {
  border-bottom: none;
  margin-bottom: 0;
  position: relative;
}

.seo-box.partialHidedP .seo-box__content:after {
  content: " ";
  background: linear-gradient(rgba(255, 255, 255, 0), #fff);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.seo-box.partialHidedP .toggle-paragraphs svg {
  transform: rotate(90deg);
}

.seo-box__header {
  background: #fff;
  margin: 0 0 10px;
  padding: 0;
  font-size: 22px;
  position: relative;
}

.seo-box__header a:hover, .seo-box__header a:focus {
  text-decoration: none;
}

.seo-box__content {
  color: #16212d;
  border-bottom: 1px solid #b2d0dd;
  margin: 0 0 20px;
  padding-bottom: 5px;
  font-size: 13px;
  line-height: 19px;
}

.seo-box__content a {
  transition: all .3s;
  display: inline;
}

.seo-box__content a:hover {
  text-decoration: underline;
}

.discussions-maminka {
  box-sizing: border-box;
  border: 1px dashed #cad5df;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 12px 22px;
  overflow: hidden;
}

.discussions-maminka .box-title {
  text-align: center;
}

.discussions-maminka .box-title h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.discussions-maminka .box-title h2 a {
  color: #00bbb7;
  font-size: 21px;
  font-weight: 700;
}

.discussions-maminka .box-title h2 a:hover {
  color: #126866;
}

.discussions-maminka .discussion-maminka {
  border-bottom: 1px solid #cad5df;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}

.discussions-maminka .discussion-maminka:after, .discussions-maminka .discussion-maminka:before {
  content: "";
  clear: both;
  display: table;
}

.discussions-maminka .discussion-maminka .discussion-header {
  float: left;
  border-right: 1px solid #cad5df;
  width: calc(100% - 76px);
}

.discussions-maminka .discussion-maminka .discussion-header .title, .discussions-maminka .discussion-maminka .discussion-header .lastmod {
  display: block;
}

.discussions-maminka .discussion-maminka .discussion-header .title {
  color: #48535f;
  margin: 0;
  font-family: sanssemibold, arial;
  font-size: 16px;
}

.discussions-maminka .discussion-maminka .discussion-header .lastmod {
  color: #5a5a5a;
  font-family: sansregular, arial;
  font-size: 13px;
}

.discussions-maminka .discussion-maminka .discussion-count-answer {
  float: right;
  width: 66px;
  padding-left: 10px;
}

.discussions-maminka .discussion-maminka .discussion-count-answer .count-answer {
  color: #00bbb7;
  z-index: 1;
  margin-left: 8px;
  font-family: sanssemibold, arial;
  font-size: 16px;
  display: inline-block;
  position: absolute;
  top: 16px;
}

.discussions-maminka .discussion-maminka .discussion-count-answer .icone-answer {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  display: inline-block;
}

.discussions-maminka .discussion-maminka.last {
  border-bottom: 0;
}

.discussions-maminka .show-more {
  color: #fff;
  text-align: center;
  background: #2dccd3;
  width: 200px;
  margin: 15px auto 0;
  padding: 10px;
  display: block;
}

.discussions-maminka .show-more:hover {
  background: #126866;
}

.discussions-maminka .show-more a {
  color: #fff;
  font-family: sanssemibold, arial;
}

.discussions-maminka .toggle-discussion {
  cursor: pointer;
  width: 40px;
  margin: 10px auto 0;
  display: block;
}

.discussions-maminka .toggle-discussion svg {
  transform: rotate(270deg);
}

.discussions-maminka .toggle-discussion path {
  fill: #00bbb7;
}

.discussions-maminka.partialHidedDiscussion .discussion-posts {
  position: relative;
}

.discussions-maminka.partialHidedDiscussion .discussion-posts:after {
  content: " ";
  z-index: 2;
  background: linear-gradient(rgba(255, 255, 255, 0), #fff);
  width: 100%;
  height: 100px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.discussions-maminka.partialHidedDiscussion .discussion-posts .discussion-maminka:not(:nth-child(-n+3)) {
  display: none;
}

.discussions-maminka.partialHidedDiscussion .toggle-discussion svg {
  transform: rotate(90deg);
}

.tipy-redakce article .title {
  margin: 0;
}

.tipy-redakce article .category-name {
  display: none;
}

.tipy-redakce article.first .article-link {
  border-top: 0;
}

.tipy-redakce .show-more {
  text-align: center;
}

.tipy-redakce .show-more a {
  color: #fff;
  background-color: #ec86d0;
  width: 200px;
  margin: 20px auto;
  padding: 10px;
  display: inline-block;
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

.loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder.item-loaded img {
  opacity: 1;
}

.loading-placeholder:not(.item-loaded) {
  background-color: #e8e8e8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.item-loaded):after {
  content: " ";
  background-color: #e8e8e8;
  background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.item-loaded) img {
  text-indent: -9999px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

body {
  color: #16212d;
  background-color: #fff;
  min-width: 320px;
  margin: 0;
  padding: 0;
  font-family: Open Sans, arial, sans-serif;
}

body.bg-color-dark {
  background-color: #1e1e1e;
}

footer {
  width: 1080px;
  margin: 0 auto;
}

.clear {
  clear: both;
}

.hidden, .hidden-xs {
  visibility: hidden;
  display: none;
}

.visible, .visible-xs {
  visibility: visible;
  display: block;
}

.delimiter {
  clear: both;
  height: 0;
  margin: -1px 0 0;
  overflow: hidden;
}

.cb {
  clear: both;
  height: 1px;
  margin-top: -1px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.icon {
  display: inline-block;
}

.padding-bottom {
  padding-bottom: 15px;
}

.padding-top {
  padding-top: 15px;
}

.border-top {
  border-top: 1px solid #cad5df;
}

.border-bottom {
  border-bottom: 1px solid #cad5df;
}

.opacity0 {
  opacity: 0;
}

.opacity10 {
  opacity: .1;
}

.opacity30 {
  opacity: .3;
}

.opacity50 {
  opacity: .5;
}

.opacity100 {
  opacity: 1;
}

.page-header {
  height: 80px;
}

.page-header h1 {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.page-header h1 > a {
  text-indent: -50px;
  color: rgba(0, 0, 0, 0);
  font-size: 1px;
  display: inline-block;
}

.page-header .dataimg-logo {
  float: left;
  width: 214px;
  height: 40px;
  display: inline-block;
}

.page-header .login {
  float: right;
  color: #6d94a6;
  text-align: center;
  margin-left: 40px;
  padding: 5px 0 0;
  font-size: 14px;
}

.page-header .login .dataimg-login {
  border: 0;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.page-header .search {
  float: right;
  width: 290px;
}

.page-header .search button {
  float: left;
  background-color: #2dccd3;
  border: 0;
  border-radius: 0 3px 3px 0;
  width: 40px;
  height: 40px;
  padding: 5px;
}

.page-header .search input[type="text"] {
  float: left;
  border: 1px solid #cad5df;
  border-right: 0;
  border-radius: 3px 0 0 3px;
  outline: none;
  width: 250px;
  height: 40px;
  padding-left: 15px;
  font-size: 13px;
}

.page-header .search input[type="text"]:focus {
  border: 1px solid #91c0d7;
  border-right: 0;
}

.page-header .search .dataimg-search {
  width: 20px;
  height: 20px;
  padding: 10px;
  display: inline-block;
}

.page-header:after {
  content: "";
  clear: both;
  height: 1px;
  margin-top: -1px;
  display: block;
}

.first .article-link {
  border-top: 1px solid #cad5df;
}

.title-box {
  color: #6d94a6;
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 20px;
  font-size: 16px;
  font-weight: 600;
}

.category-desc {
  margin-bottom: 25px;
  font-weight: 600;
  line-height: 1.5em;
}

.version-switcher {
  text-align: center;
  margin: 30px auto;
}

.version-switcher .mobile, .version-switcher .desktop {
  margin: 0 25px;
  display: inline-block;
}

.version-switcher .mobile a, .version-switcher .desktop a {
  color: #707b87;
  font-size: 24px;
}

.version-switcher .mobile a:hover, .version-switcher .desktop a:hover {
  text-decoration: underline;
}

@media (max-width: 800px) {
  body .ajax-paging {
    float: left;
    width: 100%;
    margin: 0 auto;
  }

  body .main-button {
    margin: 30px auto 60px;
  }

  body .mixed-articles article.list-article-horizontal-small {
    padding: .7em 0;
  }

  body .mixed-articles article.list-article-horizontal-small.first {
    border-bottom: 0;
    padding: .7em 0;
  }

  body .mixed-articles article.list-article-horizontal-small.no-category {
    border-top: 1px solid #b2d0dd;
    padding: .7em 0;
  }

  body .mixed-articles article.list-article-horizontal-small .title-container h2 {
    font-size: 2.5vw;
  }

  body .box-gallery {
    width: 100%;
    height: auto;
  }

  body .box-gallery .gallery-main-container .gallery .images-container {
    height: 60vw;
  }

  body .box-gallery .gallery-main-container .gallery .images-container .owl-item img {
    width: 100%;
    height: auto;
    left: 0;
    right: 0;
  }

  body .box-gallery .gallery-main-container .gallery .next {
    height: 100%;
  }

  body .box-gallery .gallery-main-container .gallery .next .image-count {
    font-size: 3vw;
  }

  body .box-gallery .gallery-main-container .gallery .prev {
    height: 100%;
  }

  body .box-gallery .image-description {
    display: none;
  }
}

@media (max-width: 425px) {
  body .mixed-articles article.list-article-horizontal-small .title-container h2 {
    font-size: 4.5vw;
  }
}

@media (max-width: 1024px) and (min-width: 750px) and (orientation: landscape) {
  body .mobile-apps {
    border-top: 1px solid #b2d0dd;
    margin: 0;
    padding: 10%;
  }

  body .mobile-apps .logo {
    width: 50%;
    margin: 0 auto;
  }

  body .mobile-apps .applications {
    width: 50%;
    margin: 60px auto 0;
  }
}

body .mobile-apps, body .edition-magazine {
  padding: 30px 0;
}

body .article-gallery-strip > ul > li, body .article-gallery-strip > ul > li:first-child {
  margin: 0 3px;
}

.title-box {
  font-weight: 600;
}

.divider {
  clear: both;
  border: none;
  border-top: 1px solid #cad5df;
  height: 1px;
  margin: 20px 0;
}

.btn-row.text-center {
  text-align: center;
}

.btn-row a.button-main, .btn-row .button-main, .btn-row .main-button {
  text-transform: uppercase;
  width: auto;
  padding: 20px;
  display: inline-block;
}

.supermamy .mimibazar-block {
  border-top: 0;
}

.supermamy .mimibazar-block h2 {
  text-transform: uppercase;
  font-family: #ec86d0;
  padding-left: 0;
  font-size: 16px;
  font-weight: 700;
}

.supermamy .mimibazar-block h2 a {
  color: #ec86d0;
}

.supermamy .mimibazar-block .item .image {
  background: #eee;
}

.supermamy .mimibazar-block .item .title, .supermamy .mimibazar-block .item .price, .supermamy .mimibazar-block .item .more {
  text-transform: none;
  font-size: 16px;
}

.supermamy .mimibazar-block .item .price {
  color: #ec86d0;
}

.supermamy .mimibazar-block .item .more {
  color: #2dccd3;
  border-bottom-color: #2dccd3;
}

.supermamy .mimibazar-block .item .more:after, .supermamy .mimibazar-block .item .more:before {
  background-color: #2dccd3;
}

.supermamy .mimibazar-block .item .more:hover {
  color: #98a3af;
}

.supermamy .mimibazar-block .item .more:hover:after, .supermamy .mimibazar-block .item .more:hover:before {
  background-color: #98a3af;
}

.supermamy .mimibazar-block .item .title {
  color: #16212d;
}

@media only screen and (min-width: 700px) {
  .supermamy .mimibazar-block .item .title {
    height: 60px;
  }
}

